<template>
  <v-main class="s-alert pt-0 mt-0" app>
    <v-alert :value="true" type="error" class="" app>
      Sua Conta foi suspensa devido a problemas no pagamento das mensalidades.
      Para regularizar sua situação, entre em contato com a nossa equipe pelo
      chat, e-mail atendimento@simplificador.com.br ou pelo telefone (11)
      4130-7932.
    </v-alert>
  </v-main>
</template>

<script>
export default {}
</script>

<style scoped lang="sass">
.s-alert
  position: fixed
  z-index: 1
  width: 100%
  top: 3.5rem
</style>
